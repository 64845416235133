
type Props = {
    width?: string;
    height?: string;
}

const PolygonIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11C12.9891 11 12.9791 11.0031 12.9681 11.0031L11.7431 8.96187C11.9016 8.67531 12 8.35063 12 8C12 7.64937 11.9019 7.32469 11.7431 7.03813L12.9681 4.99687C12.9791 4.99719 12.9891 5 13 5C14.1047 5 15 4.10469 15 3C15 1.89531 14.1047 1 13 1C12.2616 1 11.6237 1.40469 11.2775 2H4.7225C4.37625 1.40469 3.73844 1 3 1C1.89531 1 1 1.89531 1 3C1 3.73844 1.40469 4.37625 2 4.7225V11.2772C1.40469 11.6237 1 12.2616 1 13C1 14.1047 1.89531 15 3 15C3.73844 15 4.37625 14.5953 4.7225 14H11.2772C11.6237 14.5953 12.2612 15 12.9997 15C14.1044 15 14.9997 14.1047 14.9997 13C15 11.8953 14.1047 11 13 11ZM4 11.2775V4.7225C4.29948 4.54849 4.54849 4.29948 4.7225 4H11.2338L10.0319 6.00313C10.0209 6.00281 10.0109 6 10 6C8.89531 6 8 6.89531 8 8C8 9.10469 8.89531 10 10 10C10.0109 10 10.0209 9.99687 10.0319 9.99687L11.2338 12H4.7225C4.54857 11.7004 4.29955 11.4514 4 11.2775ZM9.5 8C9.5 7.72437 9.72437 7.5 10 7.5C10.2756 7.5 10.5 7.72437 10.5 8C10.5 8.27563 10.2756 8.5 10 8.5C9.72437 8.5 9.5 8.27563 9.5 8ZM13.5 3C13.5 3.27562 13.2756 3.5 13 3.5C12.7244 3.5 12.5 3.27562 12.5 3C12.5 2.72438 12.7244 2.5 13 2.5C13.2756 2.5 13.5 2.72438 13.5 3ZM3 2.5C3.27562 2.5 3.5 2.72438 3.5 3C3.5 3.27562 3.27562 3.5 3 3.5C2.72438 3.5 2.5 3.27562 2.5 3C2.5 2.72438 2.72438 2.5 3 2.5ZM2.5 13C2.5 12.7244 2.72438 12.5 3 12.5C3.27562 12.5 3.5 12.7244 3.5 13C3.5 13.2756 3.27562 13.5 3 13.5C2.72438 13.5 2.5 13.2756 2.5 13ZM13 13.5C12.7244 13.5 12.5 13.2756 12.5 13C12.5 12.7244 12.7244 12.5 13 12.5C13.2756 12.5 13.5 12.7244 13.5 13C13.5 13.2756 13.2756 13.5 13 13.5Z" fill="#4F4F4F" />
        </svg>
    )
}

export default PolygonIcon