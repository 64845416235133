import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type DbUserModalData = {
    userId: string;
    fName: string;
    mName: string;
    lName: string;
    userName: string;
    email: string;
    roleId: string;
    isBoldbiUser: boolean;
    isValid: boolean;
    isEdit: boolean;
}

export const initialDbUserModalData: DbUserModalData = {
    userId: "",
    fName: "",
    mName: "",
    lName: "",
    userName: "",
    email: "",
    roleId: "",
    isBoldbiUser: true,
    isValid: false,
    isEdit: false
}

type InitialDbUserState = {
    showDbUserModal: boolean;
    dbUserModalData: DbUserModalData
}

const initialState: InitialDbUserState = {
    showDbUserModal: false,
    dbUserModalData: initialDbUserModalData
}

export const dbDbUserSlice = createSlice({
    name: 'dbDbUser',
    initialState,
    reducers: {
        setShowDbUserModal: (state, action: PayloadAction<boolean>) => {
            state.showDbUserModal = action.payload
        },
        setDbUserModalData: (state, action: PayloadAction<DbUserModalData>) => {
            state.dbUserModalData = action.payload
        },
    },
})

export const { setShowDbUserModal, setDbUserModalData } = dbDbUserSlice.actions

export default dbDbUserSlice.reducer

