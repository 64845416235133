const appRole = (roleId: string): string => {
  if (roleId) {
    if (roleId === "2") {
      return "2";
    } else if (roleId === "3") {
      return "3";
    } else {
      return "*";
    }
  }
  return ""

};

export default appRole;
