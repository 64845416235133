import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Map, View } from 'ol'
import { CENTER, TOOLBAR_ITEMS } from '../constants/map';
import { MAX_ZOOM } from '../constants/map';
import { LayerListItem } from '../types/layer-list-item';
import { RoleMap } from '../types/layer';
import { SingleSelectionToolbarItem, ToolbarItem } from '../types/toolbar-item';
import { Geometry } from 'ol/geom';
import { AttributeQueryParam } from '../types/attribute-filter';
import VectorSource from 'ol/source/Vector';
import { source } from '@trcsolutions/ol-react'

const initialMap = new Map({
    view: new View({
        center: CENTER,
        zoom: 5,
        maxZoom: MAX_ZOOM,
    }),
});

const initalLayerState = {
    layerList: [],
    activeLayer: null
}

const initialToolbarItems = TOOLBAR_ITEMS.map((item) => ({ ...item, ...(item.selection === "multi" && { isActive: false }) })) as Array<ToolbarItem>

const initialControlState = {
    showLayerList: false,
    showToolbar: false,
    showTileBar: false,
    toolbarItems: initialToolbarItems,
    selectedDrawGeometryTool: null
}

type InitialInsightState = {
    map: Map;
    layer: {
        layerList: Array<LayerListItem>;
        activeLayer: LayerListItem | null
    };
    control: {
        showLayerList: boolean;
        showToolbar: boolean;
        showTileBar: boolean;
        toolbarItems: Array<ToolbarItem>;
        selectedDrawGeometryTool: SingleSelectionToolbarItem | null
    };
    features: Array<any>;
    selectedFeatureId: string;
    drawSource: VectorSource<Geometry>;
    drawGeometry: Geometry | null;
    attributeQueryParams: Array<AttributeQueryParam>;
    extentGeometry: string;
    selectedFilter: string | null;
}

const initialState: InitialInsightState = {
    map: initialMap,
    layer: initalLayerState,
    control: initialControlState,
    features: [],
    selectedFeatureId: "",
    drawSource: source.vector({ wrapX: false }),
    drawGeometry: null,
    attributeQueryParams: [],
    extentGeometry: "",
    selectedFilter: null,
}

export const insightSlice = createSlice({
    name: "insight",
    initialState,
    reducers: {
        setMap: (state, action: PayloadAction<Map>) => {
            state.map = action.payload
        },
        setFeatures: (state, action: PayloadAction<any>) => {
            state.features = action.payload
        },
        processRoleLayers: (state, action: PayloadAction<Array<RoleMap>>) => {
            const layerList: Array<LayerListItem> = []
            action.payload.forEach(m => {
                m.layers.forEach((l, i) => {
                    layerList.push({
                        id: l.id.toString(),
                        layerName: l.layer_name,
                        mapName: m.map_name,
                        wmsLayerName: l.wmslayer,
                        wfsLayerUrl: l.url,
                        layertitle: l.layertitle,
                        isChecked: false
                    })
                })
            })
            state.layer.layerList = layerList
            state.layer.layerList[0].isChecked = true
            state.layer.activeLayer = state.layer.layerList[0]
        },
        setLayerList: (state, action: PayloadAction<Array<LayerListItem>>) => {
            state.layer.layerList = action.payload
            const layerListItem = state.layer.layerList.find(x => x.id === state.layer.activeLayer?.id)
            if (layerListItem) {
                state.layer.activeLayer = layerListItem
            }
        },
        setActiveLayer: (state, action: PayloadAction<LayerListItem>) => {
            state.layer.activeLayer = action.payload
            state.selectedFeatureId = ""
        },
        setShowLayerList: (state, action: PayloadAction<boolean>) => {
            state.control.showLayerList = action.payload
        },
        setShowToolbar: (state, action: PayloadAction<boolean>) => {
            state.control.showToolbar = action.payload
        },
        setShowTilebar: (state, action: PayloadAction<boolean>) => {
            state.control.showTileBar = action.payload
        },
        toggleToolbarItem: (state, action: PayloadAction<string>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload)
            if (item && item.selection === "multi") {
                item.isActive = !item.isActive
            }
        },
        setToolbarItemActiveStatus: (state, action: PayloadAction<{ name: string, isActive: boolean }>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload.name)
            if (item && item.selection === "multi")
                item.isActive = action.payload.isActive
        },
        setSelectedFeatureId: (state, action: PayloadAction<string>) => {
            state.selectedFeatureId = action.payload
        },
        setSelectedDrawGeometryTool: (state, action: PayloadAction<SingleSelectionToolbarItem | null>) => {
            if (action.payload && state.control.selectedDrawGeometryTool?.name === action.payload.name) {
                state.control.selectedDrawGeometryTool = null
            } else {
                state.control.selectedDrawGeometryTool = action.payload
            }
        },
        setDrawGeometry: (state, action: PayloadAction<Geometry | null>) => {
            state.drawGeometry = action.payload
        },
        setAttributeQueryParams: (state, action: PayloadAction<Array<AttributeQueryParam>>) => {
            state.attributeQueryParams = action.payload
        },
        setExtentGeometry: (state, action: PayloadAction<string>) => {
            state.extentGeometry = action.payload
        },
        setSelectedFilter: (state, action: PayloadAction<string | null>) => {
            state.selectedFilter = action.payload
        },
    }
})

export const {
    setMap, setFeatures, setLayerList, setActiveLayer, processRoleLayers, setShowLayerList,
    setShowToolbar, setShowTilebar, setToolbarItemActiveStatus, toggleToolbarItem, setSelectedFeatureId, setSelectedDrawGeometryTool,
    setDrawGeometry, setAttributeQueryParams, setExtentGeometry, setSelectedFilter
} = insightSlice.actions

export default insightSlice.reducer