
type Props = {
    width?: string;
    height?: string;
}

const LineIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="11.7852" width="3" height="16" transform="rotate(47.4387 11.7852 0)" fill="#4F4F4F" />
        </svg>
    )
}

export default LineIcon