import { fromLonLat } from "ol/proj"
import { FitOptions } from "ol/View"
import { ToolbarItemSelection } from "../types/toolbar-item"
import ClearIcon from "assets/icons/ClearIcon"
import PointIcon from "assets/icons/PointIcon"
import LineIcon from "assets/icons/LineIcon"
import PolygonIcon from "assets/icons/PolygonIcon"
import AttributeFilterIcon from "assets/icons/AttributeFilterIcon"
import ExtentFilterIcon from "assets/icons/ExtentFilterIcon"
import GeometryFilterIcon from "assets/icons/GeometryFilterIcon"
import SelectionFilterIcon from "assets/icons/SelectionFilterIcon"
import LegendIcon from "assets/icons/LegendIcon"
import BookmarkIcon from "assets/icons/BookmarkIcon"
import TableIcon from "assets/icons/TableIcon"
import CommentBoxIcon from "assets/icons/CommentBoxIcon"
import InfoIcon from "assets/icons/InfoIcon"

export const EXTENT_OPS: FitOptions = {
    padding: [100, 100, 100, 100],
    duration: 500,
    maxZoom: 19
}

export const CENTER = fromLonLat([-73.95, 40.77])

export const SATELLITE_BASEMAP_URL = 'http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}'
export const Google_LayerHybrid_URL = 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
export const Google_LayerTerrain_URL = 'http://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}'
export const Clear_TOOL = "Clear"
export const POINT_TOOL = "Point"
export const LINE_TOOL = "Line"
export const POLYGON_TOOL = "Polygon"
export const GEOMETRY_FILTER_TOOL = "Geometric Filter"
export const ATTRIBUTE_FILTER_TOOL = "Attribute Filter"
export const EXTENT_FILTER_TOOL = "Extent Filter"
export const SELECTION_FILTER_TOOL = "Selection Filter"
export const INFO_TOOL = "Feature Info"
export const LEGEND_TOOL = "Map Legend"
export const BOOKMARK_TOOL = "Bookmark"
export const FEATURE_TABLE_TOOL = "Feature Table"

export const DRAW_TOOL = "drawTool"
export const FILTER = "filter"

export const TOOLBAR_ITEMS: Array<{ name: string, icon: any, activeIcon?: any, selection: ToolbarItemSelection, id?: string }> = [
    {
        name: Clear_TOOL,
        icon: ClearIcon,
        selection: "none"
    },
    {
        name: POINT_TOOL,
        icon: PointIcon,
        selection: "single",
        id: DRAW_TOOL
    },
    {
        name: LINE_TOOL,
        icon: LineIcon,
        selection: "single",
        id: DRAW_TOOL
    },
    {
        name: POLYGON_TOOL,
        icon: PolygonIcon,
        selection: "single",
        id: DRAW_TOOL
    },
    {
        name: ATTRIBUTE_FILTER_TOOL,
        icon: AttributeFilterIcon,
        selection: "single",
        id: FILTER
    },
    {
        name: EXTENT_FILTER_TOOL,
        icon: ExtentFilterIcon,
        selection: "single",
        id: FILTER
    },
    {
        name: GEOMETRY_FILTER_TOOL,
        icon: GeometryFilterIcon,
        selection: "single",
        id: FILTER
    },
    {
        name: SELECTION_FILTER_TOOL,
        icon: SelectionFilterIcon,
        selection: "single",
        id: FILTER
    },
    {
        name: INFO_TOOL,
        activeIcon: CommentBoxIcon,
        icon: InfoIcon,
        selection: "multi"
    },
    {
        name: LEGEND_TOOL,
        icon: LegendIcon,
        selection: "multi"
    },
    {
        name: BOOKMARK_TOOL,
        icon: BookmarkIcon,
        selection: "multi"
    },
    {
        name: FEATURE_TABLE_TOOL,
        icon: TableIcon,
        selection: "multi"
    },
]

export const MAX_ZOOM = 22

export const OSM_BASEMAP = "OSM"
export const SATELLITE_BASEMAP = "Satellite"
export const TERRAIN_BASEMAP = "Terrain"
export const HYBRID_BASEMAP = "Hybrid"


export const ATTR_FILTER_CONDITION_NUMBER_OPTIONS = [
    { label: "Greater Than", value: ">" },
    { label: "Greater Than Equal To", value: ">=" },
    { label: "Less Than", value: "<" },
    { label: "Less Than Equal To", value: "<=" },
    { label: "Equals to", value: "=" },
];

export const ATTR_FILTER_CONDITION_OPTIONS = [
    { label: "Equals to", value: "=" }
]

export const FEATURE_INFO_OVERLAY_ID = "featureInfoOverlay"

export const HIGHLIGHT_FEATURE_OVERLAY_ID = "highlightFeatureOverlay"