import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type TeamModalData = {
    teamId: string;
    isValid: boolean;
    isEdit: boolean;
}

export const initialTeamModalData: TeamModalData = {
    teamId: "",
    isValid: false,
    isEdit: false
}

type InitialTeamState = {
    showTeamModal: boolean;
    teamModalData: TeamModalData;
}

const initialState: InitialTeamState = {
    showTeamModal: false,
    teamModalData: initialTeamModalData,
}

export const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setShowTeamModal: (state, action: PayloadAction<boolean>) => {
            state.showTeamModal = action.payload
        },
        setTeamModalData: (state, action: PayloadAction<TeamModalData>) => {
            state.teamModalData = action.payload
        },
    },
})

export const { setShowTeamModal, setTeamModalData, } = teamSlice.actions

export default teamSlice.reducer

