import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BulkValidaitonLayerRuleListItem } from '../types/bulk-validation'
import { MapLayerRuleSet } from '../types/layer'

type InitialBulkValidationState = {
    bulkValidLayerList: Array<BulkValidaitonLayerRuleListItem>
}

const initialState: InitialBulkValidationState = {
    bulkValidLayerList: []
}

export const bulkValidationSlice = createSlice({
    name: "bulkValidation",
    initialState,
    reducers: {
        resetBulkValidationInstance: () => initialState,
        processBulkValidMapLayerRuleSet: (state, action: PayloadAction<Array<MapLayerRuleSet>>) => {
            state.bulkValidLayerList = action.payload.map(m => m.LayerRule.map((l, i) => {
                return {
                    ...l,
                    id: l.id,
                    isChecked: false,
                }
            })).flat(1)
        },
        setBulkValidLayerList: (state, action: PayloadAction<Array<BulkValidaitonLayerRuleListItem>>) => {
            state.bulkValidLayerList = action.payload
        },
    }
})

export const { processBulkValidMapLayerRuleSet, setBulkValidLayerList, resetBulkValidationInstance } = bulkValidationSlice.actions

export default bulkValidationSlice.reducer