import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { WidgetInstanceWithRole } from 'types/widget-instance';

type RoleModalData = {
    roleId: string;
    role_name: string;
    isValid: boolean;
    isEdit: boolean;
}

export const initialRoleModalData: RoleModalData = {
    roleId: "",
    role_name: "",
    isValid: false,
    isEdit: false
}

type WidgetInstListModalData = {
    widgetInstancesByRole: Array<WidgetInstanceWithRole>;
    isLoading: boolean;
    isError: boolean;
}

const initialWidgetInstListModalData: WidgetInstListModalData = {
    widgetInstancesByRole: [],
    isLoading: false,
    isError: false,
}

type InitialRoleState = {
    showRoleModal: boolean;
    roleModalData: RoleModalData;
    showWidgetInstListModal: boolean;
    widgetInstListModalData: WidgetInstListModalData
}

const initialState: InitialRoleState = {
    showRoleModal: false,
    roleModalData: initialRoleModalData,
    showWidgetInstListModal: false,
    widgetInstListModalData: initialWidgetInstListModalData
}

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setShowRoleModal: (state, action: PayloadAction<boolean>) => {
            state.showRoleModal = action.payload
        },
        setRoleModalData: (state, action: PayloadAction<RoleModalData>) => {
            state.roleModalData = action.payload
        },
        setShowWidgetInstListModal: (state, action: PayloadAction<boolean>) => {
            state.showWidgetInstListModal = action.payload
        },
        setWidgetInstListModalData: (state, action: PayloadAction<WidgetInstListModalData>) => {
            state.widgetInstListModalData = action.payload
        },
    },
})

export const { setShowRoleModal, setRoleModalData, setShowWidgetInstListModal, setWidgetInstListModalData } = roleSlice.actions

export default roleSlice.reducer

