
type Props = {
    width?: string;
    height?: string;
}

const ClearIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-width="0.5" stroke="black" d="M3.13802 2.19531L2.19531 3.13802L7.05729 8L2.19531 12.862L3.13802 13.8047L8 8.94271L12.862 13.8047L13.8047 12.862L8.94271 8L13.8047 3.13802L12.862 2.19531L8 7.05729L3.13802 2.19531Z" fill="#4F4F4F" />
        </svg>
    )
}

export default ClearIcon