import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const access_token = localStorage.getItem('access_token') ?? ''

type InitialAuthState = {
    access_token: string;
}

const initialState: InitialAuthState = {
    access_token,
}

export const boldBISlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.access_token = action.payload
        }
    },
})

export const { setAccessToken } = boldBISlice.actions

export default boldBISlice.reducer

