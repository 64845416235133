import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type InitialWidgetState = {
    activeWidgetInstanceId: string | null;
}

const initialState: InitialWidgetState = {
    activeWidgetInstanceId: null,
}

export const widgetDashboardSlice = createSlice({
    name: "widgetDashboard",
    initialState,
    reducers: {
        setActiveWidgetInstanceId: (state, action: PayloadAction<string | null>) => {
            state.activeWidgetInstanceId = action.payload
        },
    }
})

export const { setActiveWidgetInstanceId } = widgetDashboardSlice.actions

export default widgetDashboardSlice.reducer