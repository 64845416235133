import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type AnalyticModalData = {
    analyticId: string;
    AnalyticsName: string;
    AnalyticsLable: string;
    EngineData: {
        id: string;
        rulesets: Array<{
            id: string
        }>
    };
    isValid: boolean;
    isEdit: boolean;
}

type EngineModalData = {
    engine_id?:number;
    engine_name:string;
    id:string;
    is_active:boolean;
    isEdit: boolean,
    ruleset_label:string;
    ruleset_name:string;
    url:string,
}

export const initialAnalyticModalData: AnalyticModalData = {
    analyticId: "",
    AnalyticsName: "",
    AnalyticsLable: "",
    EngineData: {
        id: "",
        rulesets: []
    },
    isValid: false,
    isEdit: false
}

export const initialEngineModalData:EngineModalData = {
    engine_name:'',
    id:'',
    is_active:false,
    ruleset_label:'',
    ruleset_name:'',
    url:'',
    isEdit: false
}

type SectorAssignModalData = {
    analyticId: string;
    AnalyticsName: string;
    AnalyticsLable: string;
    sectorIds: Array<string>;
    isValid: boolean;
}

export const initialSectorAssignModalData: SectorAssignModalData = {
    analyticId: "",
    AnalyticsName: "",
    AnalyticsLable: "",
    sectorIds: [],
    isValid: false
}

type InitialAnalyticState = {
    showAnalyticModal: boolean;
    analyticModalData: AnalyticModalData;
    engineModalData:EngineModalData;
    showSectorAssignModal: boolean;
    showEngineModal:boolean;
    sectorAssignModalData: SectorAssignModalData
}

const initialState: InitialAnalyticState = {
    showAnalyticModal: false,
    analyticModalData: initialAnalyticModalData,
    engineModalData:initialEngineModalData,
    showSectorAssignModal: false,
    showEngineModal:false,
    sectorAssignModalData: initialSectorAssignModalData
}

export const analyticSlice = createSlice({
    name: 'analytic',
    initialState,
    reducers: {
        setShowAnalyticModal: (state, action: PayloadAction<boolean>) => {
            state.showAnalyticModal = action.payload
        },
        setShowEngineModal: (state, action: PayloadAction<boolean>) => {
            state.showEngineModal = action.payload
        },
        setAnalyticModalData: (state, action: PayloadAction<AnalyticModalData>) => {
            state.analyticModalData = action.payload
        },
        setEngineModalData: (state, action: PayloadAction<EngineModalData>) => {
            state.engineModalData = action.payload
        },
        setShowSectorAssignModal: (state, action: PayloadAction<boolean>) => {
            state.showSectorAssignModal = action.payload
        },
        setSectorAssignModalData: (state, action: PayloadAction<SectorAssignModalData>) => {
            state.sectorAssignModalData = action.payload
        },
    },
})

export const { setShowAnalyticModal, setAnalyticModalData,setEngineModalData, setShowSectorAssignModal, setSectorAssignModalData,setShowEngineModal} = analyticSlice.actions

export default analyticSlice.reducer

