import React from "react";
import "./errorPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

type Props = {
  status?: string;
  title?: string;
  message: string;
};

const ErrorPage = (props: Props) => {
  const { status, title = "OOPS!", message } = props;
  return (
    <div className="error_page">
      {status && <h1 className="animated fadeIn text-primary">{status}</h1>}
      <div className="errorMsg animated fadeInUp">
        <span className="error_Header">{title}</span>
        <span className="error_Response mt-1">{message}</span>
      </div>
      <Link
        to={"/"}
        className="btn btn-primary backtoHome mt-4 animated fadeInUp"
      >
        <span className="btn-label me-2">
          <FontAwesomeIcon icon={faHome} />
        </span>
        Back To Home
      </Link>
    </div>
  );
};

export default ErrorPage;
