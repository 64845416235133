
type Props = {
    width?: string;
    height?: string;
}

const SelectionFilterIcon = ({ width = "17", height = "13" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path id="S" d="M17.1672 16.098C16.0192 16.098 15.0765 15.8273 14.3392 15.286C13.6018 14.7353 13.2052 13.9513 13.1492 12.934H16.0752C16.1032 13.2793 16.2058 13.5313 16.3832 13.69C16.5605 13.8487 16.7892 13.928 17.0692 13.928C17.3212 13.928 17.5265 13.8673 17.6852 13.746C17.8532 13.6153 17.9372 13.438 17.9372 13.214C17.9372 12.9247 17.8018 12.7007 17.5312 12.542C17.2605 12.3833 16.8218 12.206 16.2152 12.01C15.5712 11.7953 15.0485 11.59 14.6472 11.394C14.2552 11.1887 13.9098 10.8947 13.6112 10.512C13.3218 10.12 13.1772 9.61133 13.1772 8.986C13.1772 8.35133 13.3358 7.81 13.6532 7.362C13.9705 6.90467 14.4092 6.55933 14.9692 6.326C15.5292 6.09267 16.1638 5.976 16.8732 5.976C18.0212 5.976 18.9358 6.24667 19.6172 6.788C20.3078 7.32 20.6765 8.07133 20.7232 9.042H17.7412C17.7318 8.74333 17.6385 8.51933 17.4612 8.37C17.2932 8.22067 17.0738 8.146 16.8032 8.146C16.5978 8.146 16.4298 8.20667 16.2992 8.328C16.1685 8.44933 16.1032 8.622 16.1032 8.846C16.1032 9.03267 16.1732 9.196 16.3132 9.336C16.4625 9.46667 16.6445 9.58333 16.8592 9.686C17.0738 9.77933 17.3912 9.90067 17.8112 10.05C18.4365 10.2647 18.9498 10.4793 19.3512 10.694C19.7618 10.8993 20.1118 11.1933 20.4012 11.576C20.6998 11.9493 20.8492 12.4253 20.8492 13.004C20.8492 13.592 20.6998 14.1193 20.4012 14.586C20.1118 15.0527 19.6872 15.4213 19.1272 15.692C18.5765 15.9627 17.9232 16.098 17.1672 16.098Z" fill="#4F4F4F" />
                <path id="Vector" d="M15.2492 0H0.750872C0.0846841 0 -0.251472 0.808313 0.220559 1.28034L6 7.06066V13.5C6 13.7447 6.1194 13.9741 6.3199 14.1144L8.8199 15.8638C9.31312 16.2091 10 15.8592 10 15.2494V7.06066L15.7796 1.28034C16.2507 0.80925 15.9168 0 15.2492 0Z" fill="#4F4F4F" />
            </g>
        </svg>
    )
}

export default SelectionFilterIcon