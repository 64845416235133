
type Props = {
    width?: string;
    height?: string;
}

const ExtentFilterIcon = ({ width = "17", height = "13" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2492 0H0.750872C0.0846841 0 -0.251472 0.808313 0.220559 1.28034L6 7.06066V13.5C6 13.7447 6.1194 13.9741 6.3199 14.1144L8.8199 15.8638C9.31312 16.2091 10 15.8592 10 15.2494V7.06066L15.7796 1.28034C16.2507 0.80925 15.9168 0 15.2492 0Z" fill="#4F4F4F" />
            <path d="M16.693 8.328V9.938H19.843V12.024H16.693V13.802H20.263V16H13.949V6.13H20.263V8.328H16.693Z" fill="#4F4F4F" />
        </svg>
    )
}

export default ExtentFilterIcon