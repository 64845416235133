
import { useRouteError } from "react-router-dom";
import ErrorPage from './errorPage/ErrorPage';

const ErrorBoundary = () => {
    const error: any = useRouteError();
    const is404 = error.status && error.status === 404

    if (is404) return <ErrorPage status='404' message='Page Not Found.' />

    return <ErrorPage message='Something went wrong :(' />

}

export default ErrorBoundary